<script setup lang="ts">
defineOptions({
  name: 'Default layout',
})
const isSidebarOpen = ref(false)

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}

const route = useRoute()

const scrollY = computed(() => route.meta?.scrollY ?? true)
</script>

<template>
  <div class="bg-white h-dvh fixed inset-0 flex">
    <div
      v-if="isSidebarOpen"
      class="md:hidden h-dvh fixed inset-0 flex max-md:z-10 w-full bg-black/80"
      @click="isSidebarOpen = false"
    >
      <LazySideMenu
        @click.stop
        class="max-md:inset-y-0 max-md:left-0 max-md:w-64 max-md:bg-gray-900 max-md:shadow-lg max-md:z-10 md:static md:w-auto"
      ></LazySideMenu>
      <Button
        @click="toggleSidebar()"
        v-if="isSidebarOpen == true"
        variant="ghost"
        class="max-md:z-10 px-4 py-8"
      >
        <LazyIconX class="text-white" />
      </Button>
    </div>
    <LazySideMenu class="max-md:hidden"></LazySideMenu>
    <div class="flex-grow flex flex-col min-w-0 max-w-full">
      <LazyTopBar @toggle="toggleSidebar()"></LazyTopBar>
      <div
        class="flex-grow"
        :class="{ 'overflow-y-scroll': scrollY, 'min-h-0': !scrollY }"
        data-item="default-layout-parent-el"
      >
        <slot></slot>
      </div>
    </div>
  </div>
  <!-- <div class="bg-slate-50 h-dvh overflow-y-scroll fixed inset-0" data-item="default-layout-parent-el">
		<template v-if="!isLoginPage && !isIntegrationsPage">
			<TooltipProvider :delay-duration="0">
				<Tooltip>
					<TooltipTrigger as-child>
						<button @click="navigateTo('/')"
							class="fixed start-5 top-5 w-10 h-10 rounded-full flex items-center justify-center">
							<LazyIconHouse class="w-6 h-6" :stroke-width="1.5" />
						</button>
					</TooltipTrigger>
					<TooltipContent class="bg-slate-800 text-xs px-3 h-7 shadown-none border-none text-white" :side="'bottom'">
						Home
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<TooltipProvider :delay-duration="0">
				<Tooltip>
					<TooltipTrigger as-child>
						<button v-if="session?.user?.user_metadata?.isAdmin" @click="navigateTo('/employees')"
							class="fixed start-5 top-20 w-10 h-10 flex items-center justify-center">
							<LazyIconUsers class="w-6 h-6" :stroke-width="1.5" />
						</button>
					</TooltipTrigger>
					<TooltipContent class="bg-slate-800 text-xs px-3 h-7 shadown-none border-none text-white" :side="'bottom'">
						Employees
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<div class="w-[350px] fixed end-0 top-0">
				<Popover>
					<PopoverTrigger as-child class="cursor-pointer">
						<Avatar @click="hoverState != hoverState" class="cursor-pointer fixed end-8 top-5"
							style="overflow: visible;">
							<AvatarImage class="rounded-full" :src="userAvatarUrl" referrerpolicy="no-referrer" />
							<AvatarFallback class="rounded-full">CN</AvatarFallback>
							<span v-if="homePageInsights.hasPendingOrInProgressAsset" class="absolute start-0 bottom-0 flex h-3 w-3">
								<span
									class="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
								<span class="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
							</span>
						</Avatar>
					</PopoverTrigger>
					<PopoverContent side="bottom" :side-offset="10"
						class="bg-white shadow-md p-5 rounded-sm border-inherit border w-[350px] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade">
						<div>
							<div class="flex justify-between mb-2">
								<h3 class="text-md font-semibold">My Integrations</h3>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger as-child>
											<nuxt-link to="/integrations" variant="link">
												<LazyIconCirclePlus class="w-4 h-4" />
											</nuxt-link>
										</TooltipTrigger>
										<TooltipContent>
											Add more integrations
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</div>
							<div v-for="n in [
								{
									icon: 'google-drive',
									name: 'Google Drive',
									dataSourceName: 'google-drive'
								},
							]" class="flex space-x-4 items-center cursor-pointer"
								@click="navigateTo(`/overview?dataSource=${n.dataSourceName}`)">
								<NuxtIcon :name="n.icon" class="mt-2" style="font-size: 30px"></NuxtIcon>
								<div class="flex justify-between flex-grow">
									<h4 class="text-sm flex font-semibold space-x-1">
										{{ n.name }}
									</h4>
									<Badge v-if="homePageInsights.hasPendingOrInProgressAsset" class="text-xs" variant="outline">Sync In
										Progress...</Badge>
								</div>
							</div>
						</div>
					</PopoverContent>
				</Popover>
			</div>

			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger as-child>
						<button @click="logout" :disabled="signoutLoading" variant="outline" class="fixed bottom-5 end-8">
							<LazyIconLoader v-if="signoutLoading" class="w-5 h-5 animate-spin" />
							<LazyIconLogOut v-else class="w-5 h-5" />
						</button>
					</TooltipTrigger>
					<TooltipContent :side-offset="15">
						Logout
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</template>
<slot></slot>
</div> -->
</template>

<style lang="scss" scoped></style>
